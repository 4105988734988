import {
  UserAnswers,
  convertUserDataFromQueryParamsToObject,
} from '~/utils/queryParameters'
import { SETTINGS } from '~/utils/settings'

type HandleScoreParams = {
  questions?:
    | readonly (Queries.QuestionDataFragment | null)[]
    | undefined
    | null
  profiles?: readonly (Queries.ProfileDataFragment | null)[] | undefined | null
  userAnswers?: UserAnswers
}

const safeAccess = <T>(obj: T, key: keyof any): any => {
  return obj[key as keyof T]
}
const { userAnswers: userAnswersFromParams } =
  convertUserDataFromQueryParamsToObject() || {}

export const handleScore = ({
  questions,
  profiles,
  userAnswers = userAnswersFromParams,
}: HandleScoreParams) => {
  const questionsWithStaticData = (
    question: Queries.QuestionDataFragment | null,
    questionIndex: number,
  ) => ({
    ...question,
    index: questionIndex,
    answers: [...Array(SETTINGS.answersMaxCount).keys()].map((answerIndex) => ({
      answerIndex: answerIndex,
      title: safeAccess(question, `answer${answerIndex + 1}`),
      score: safeAccess(question, `answer${answerIndex + 1}Score`),
      evaluationText: safeAccess(
        question,
        `evaluationTextAnswer${answerIndex + 1}` as const,
      ),
      correctAnswerMCQ: question?.correctAnswerMCQ,
      isAnswerSelected:
        userAnswers?.find((q) => q?.userAnswerIndex === answerIndex + 1)
          ?.userAnswerIndex ===
        answerIndex + 1,
    })),
  })

  const questionsWithDynamicData = questions
    ?.map(questionsWithStaticData)
    ?.map((question, questionIndex) => {
      const userAnswerIndex = userAnswers?.find(
        (q: any) => q.questionIndex === questionIndex,
      )?.userAnswerIndex

      const selectedAnswer = question.answers.find(
        (answer) => answer?.answerIndex === userAnswerIndex,
      )

      return {
        ...question,
        answers: question.answers.map((answer, answerIndex) => ({
          isAnswerSelected: answerIndex === userAnswerIndex ? true : false,
          answerTitle: answer?.title,
          evaluationText: safeAccess(
            question,
            `evaluationTextAnswer${answerIndex + 1}` as const,
          ),
        })),
        evaluationText: selectedAnswer?.evaluationText,
        user: {
          answerScore: selectedAnswer?.score,
          isAnswerCorrect:
            (userAnswerIndex ?? 0) + 1 === question.correctAnswerMCQ,
          answerIndex: (userAnswerIndex ?? 0) + 1,
        },
      }
    })

  const userGlobalScore = () => {
    const scoreByAnswersScore =
      questionsWithDynamicData?.reduce(
        (acc, question) => acc + (question.user.answerScore - 1 || 0),
        0,
      ) || 0
    const questionCount = questions?.length ?? 0
    const scoreByAnswersScoreMax = questionCount * 3
    const scoreByCorrectAnswer =
      questionsWithDynamicData?.reduce((acc, question) => {
        return (
          acc +
          ((question.correctAnswerMCQ ?? 0) === question.user.answerIndex
            ? 1
            : 0)
        )
      }, 0) || 0
    const scorePercentage = Math.round(
      (scoreByAnswersScore / scoreByAnswersScoreMax) * 100,
    )
    const scorePercentageByCorrectAnswer = Math.round(
      (scoreByCorrectAnswer / questionCount) * 100,
    )

    const userProfile = profiles?.find(
      (profile) =>
        scorePercentage >= (profile?.from ?? 0) &&
        scorePercentage <= (profile?.to ?? 100),
    )
    const userProfilePercentageGtm = `${userProfile?.from ?? 0}-${userProfile?.to ?? 100}`

    return {
      scoreByCorrectAnswer,
      scoreByAnswersScore,
      scoreByAnswersScoreMax,
      scorePercentageByCorrectAnswer,
      scorePercentage,
      questionCount,
      userProfile,
      userProfilePercentageGtm,
    }
  }

  const returnValues = {
    user: userGlobalScore(),
    questionsWithDynamicData,
  }

  return {
    ...returnValues,
  }
}
