import {
  FORM_FIELDS_CONFIG,
  type DATABASE_FIELDS,
} from '~/components/features/Form/formUtils'
import { SETTINGS } from '~/utils/settings'

const API_ENDPOINT = `${SETTINGS.apiFile}`

export const validateForm = (formData: DATABASE_FIELDS) => {
  let errors: { [key: string]: string } = {}
  let isValid = true

  const keepOnlyTheseFields =
    SETTINGS.formFieldsToDisplayWithSorting as string[]

  FORM_FIELDS_CONFIG.forEach(
    ({ name, validate, isMandatory, errorMessage }) => {
      if (keepOnlyTheseFields.includes(name)) {
        const value = formData[name]
        if (!!isMandatory && value !== undefined && !validate(value)) {
          isValid = false
          errors[name] = errorMessage
        }
      }
    },
  )

  return { isValid, errors }
}

export const submitQuizForm = async (
  formData: DATABASE_FIELDS,
): Promise<string> => {
  const endpoint = `https://${window.location.hostname}/api/${API_ENDPOINT}`

  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    })

    if (!response.ok) {
      throw new Error('La soumission a échoué')
    }

    const data = await response.json()
    return `${data.message}, vous allez etre redirigé... `
  } catch (error) {
    console.error('Erreur lors de la soumission:', error)
    throw new Error('❌ Une erreur est survenue, veuillez réessayer.')
  }
}
